<template>
  <div class="m2 wrapper">
    <Loading v-if="loading" />
    <div v-else>
      <div class="flex _row spacearound">
        <div class="flex _row m2">
          <div class="employee_search">Medewerker zoeken: </div>
          <input
            @keyup="search"
            v-model="search_query"
            class="search_input"
            placeholder="Medewerker zoeken"
          />
        </div>
        <button class="m2 employee_download" @click="createExcel">Telefoonlijst downloaden</button>
      </div>
      <div class="flexbox spaceevenly">
        <div
          v-for="(item, key) in employees"
          :key="key"
          class="m2 p2 flex _column employee_card"
        >
          <img
            class="employee_image"
            :src="`https://kleyn-intranet.s3.eu-west-1.amazonaws.com/intranet_fotos/${item.foto}`"
          />
          <div class="employee_details">
            <div class="_text-center employee_sub">
              <div class="bold">
                {{
                  fullName(item.voornaam, item.tussenvoegsel, item.achternaam)
                }}
              </div>
              <hr class="employee_divider" />
              <div>{{ item.functie }}</div>
            </div>
            <div class="employee_fill--empty" />
            <div class="p1">
              <i class="fas fa-envelope"></i>
              {{ item.email }}<br />
              <i class="fas fa-mobile"></i>
              {{ item.telefoonnummer }}<br />
              <i class="fas fa-phone"></i>
              {{ item.intern }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";
import hasIndex from "@/functions/hasIndex";
import * as XLSX from "xlsx";
export default {
  components: { Loading },
  data: () => ({
    original_employees: null,
    loading: true,
    employees: null,
    search_query: null,
  }),
  created() {
    this.getData();
  },
  methods: {
    getData() {
      request(`employee`, "GET").then(({ employees }) => {
        this.employees = employees;
        this.original_employees = employees;
        this.loading = false;
      });
    },

    fullName(first_name, preposition, last_name) {
      if (!preposition) return first_name + " " + last_name;
      return first_name + " " + preposition + " " + last_name;
    },

    createExcel() {
      let telefoonlijst = [];
      this.employees.forEach((i) => {
        telefoonlijst.push({
          Voornaam: i.voornaam,
          Tussenvoegsel: i.tussenvoegsel,
          Achternaam: i.achternaam,
          "Interne nummer": i.intern,
          "Mobiel nummer": i.telefoonnummer,
          "E-Mail": i.email,
        });
      });

      var telWS = XLSX.utils.json_to_sheet(telefoonlijst);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        wb,
        telWS,
        `Telefoonlijst_${this.moment().format("DD-MM-YYYY")}`
      );
      XLSX.writeFile(
        wb,
        `Telefoonlijst_${this.moment().format("DD-MM-YYYY")}.xlsx`
      );
    },

    search() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (this.search_query == "") this.employees = this.original_employees;
        if (this.search_query != "") {
          const filteredEmployees = this.original_employees.filter(
            (item) =>
              hasIndex(item.voornaam, this.search_query) ||
              hasIndex(item.achternaam, this.search_query)
          );
          this.employees = filteredEmployees;
        }
      }, 100);
    },
  },
};
</script>
